import React from "react";
import { cdnList } from "../../utils/Utils";

const GooglePlayImage = ({ linkClass, imgClass }) => {
  return (
    <>
      <a
        className={linkClass}
        href="/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={cdnList.android}
          alt="Google Play Store"
          className={imgClass}
        />
      </a>
    </>
  );
};

export default GooglePlayImage;
