import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = ({ title }) => {

  useEffect(() => {
    document.title = title;
  }, [title]);
  
  return (
    <>
      <h2 className="font-bold text-4xl py-5">Privacy Policy</h2>
      <p className="my-2 mb-10">
        We at Five&App value and respect the privacy of our users. This Privacy
        Policy outlines the information we collect from users of our Ho-Lo
        mobile app, and how we use, protect, and disclose that information. By
        using our App, you consent to the collection and processing of your
        information as described in this Privacy Policy.
      </p>
      <p className="mt-5 font-bold">What personal information do we collect?</p>
      <p className="my-2">
        We only collect this information with your explicit consent and solely
        for the purpose of creating your account. These information are
        collected when you sign up for Ho-Lo:
      </p>
      <p className="my-3">
        <ol className="list-decimal">
          <li className="list-inside">Full Name</li>
          <li className="list-inside">Email Address</li>
          <li className="list-inside">Real Estate Broker PRC ID Number</li>
          <li className="list-inside">Real Estate Sales Accreditation Number</li>
        </ol>
      </p>
      <p className="my-2 mb-10">
        The Ho-Lo app can also be used as a guest that has limited features, but
        does not collect any user information.
      </p>
      <p className="mt-5 font-bold">How do we use your personal information?</p>
      <p className="my-2 mb-10">
        We only collect this information with your explicit consent and solely
        for the purpose of creating your account. Your PRC ID number is needed
        to properly identify you as a certified broker or real estate agent with
        legal authority to practice their profession. We may use your email
        address to communicate with you regarding customer support inquiries,
        feedback, and other service-related matters.
      </p>
      <p className="mt-5 font-bold">
        How do we protect your personal information?
      </p>
      <p className="my-2 mb-10">
        We are committed to ensuring the security of your personal information
        by continually improving our system{"'"}s sercurity measures. Your data
        can only be accessed by authorized personnel within Five&App, who have
        satisfied our information security and data privacy requirements. We do
        not share your personal information to any third-party organizations or
        services.
      </p>
      <p className="my-2 mb-10">
        While we do our best to protect your personal information, please note
        that no method of data transmission over the internet or electronic
        storage is 100% secure, and we cannot guarantee absolute security. In
        the event that you would like for you personal information to be deleted
        from our system, follow the instructions in this <Link to="/delete-user" className="no-underline hover:underline text-blue-600">LINK</Link> to opt out from
        Ho-Lo.
      </p>
      <p className="mt-5 font-bold">Changes to the Privacy Policy</p>
      <p className="my-2 mb-10">
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or legal requirements. We encourage you to review this
        Privacy Policy periodically for any updates. Your continued use of Ho-Lo
        after any modifications to the Privacy Policy will signify your
        acceptance of the updated terms.
      </p>
    </>
  );
};

export default PrivacyPolicy;
