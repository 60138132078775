import React from "react";
import InputErrorMessage from "../../InputErrorMessage";
import { Field } from "formik";

const FieldFormik = ({
  id = "",
  type = "",
  name = "",
  error = "",
  touched = "",
  label = "",
  labelBackground = "bg-holo-inverse",
  ...props
}) => {
  return (
    <>
      <div className="relative">
        <Field
          id={id}
          type={type}
          name={name}
          className={`block px-2.5 pb-2.5 pt-4 w-full text-sm bg-transparent border-gray-400 text-gray-900 rounded-lg border appearance-none peer no-outline ${
            error && touched && "border-red-500"
          }`}
          {...props}
        />
        <label
          htmlFor={id}
          className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] ${labelBackground} px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ${
            error && touched && "text-red-500"
          }`}
        >
          {label}
        </label>
      </div>
      <InputErrorMessage error={error} touched={touched} />
    </>
  );
};

export default FieldFormik;
