import React from "react";
import { Link } from "react-router-dom";
import GooglePlayImage from "./GooglePlayImage";
import { cdnList } from "../../utils/Utils";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="container mx-auto p-4">
      <img src={cdnList.holoFooter} alt="ho-lo" />
      <div className="flex flex-col md:flex-row justify-between items-end">
        <div>
          <h1 className="mt-5 text-2xl font-bold">
            Download the app today and start planning your dream home!
          </h1>
        </div>
        <div className="w-full md:w-fit lg:w-fit">
          <GooglePlayImage linkClass="" imgClass="selft-start mt-3" />
        </div>
      </div>
      <hr className="border-holo-primary border-1 mt-5 mb-5" />
      <div className="flex flex-col md:flex-row items-center md:justify-between">
        <div className="order-1 md:order-2 mb-4 md:mb-0 w-full md:w-fit lg:w-fit text-center sm:text-left">
          <Link to="/privacy-policy" className="p-3 hover:text-holo-primary">
            Privacy Policy
          </Link>
          {/* <Link to="/" className="p-3 hover:text-holo-primary">
            About Us
          </Link> */}
          <hr className="border-holo-primary border-1 mt-5 block md:hidden lg:hidden" />
        </div>
        <div className="order-2 md:order-1 w-full md:w-fit lg:w-fit">
          <p className="text-gray-600">
            <small>&#169;{currentYear} Five&App OPC</small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
