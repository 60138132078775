import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  axiosDefault,
  calculateRemainingTime,
  cdnList,
} from "../../../utils/Utils";
import FieldFormik from "../UI/Formik/FieldFormik";
import { localData } from "../../../utils/Utils";

const validationSchema = Yup.object().shape({
  code: Yup.number().required("Confirmation code is required"),
});

const { OTPData } = localData;

const DeleteUserForm = ({
  email,
  setSuccess,
  setOTPSentTimestamp,
  OTPSentTimestamp,
  setInternalError,
}) => {
  const initialCountdown = process.env.REACT_APP_OTP_RESET_TIME * 60;

  const [countdown, setCountdown] = useState(
    calculateRemainingTime(OTPSentTimestamp, initialCountdown)
  );

  const [showResend, setShowResend] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const [isResendError, setIsResendError] = useState(false);
  const [resendError, setResendError] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(calculateRemainingTime(OTPSentTimestamp, initialCountdown));
    }, 1000);

    if (countdown <= 0) {
      setShowResend(true);
    }

    return () => {
      clearInterval(timer);
    };
  }, [OTPSentTimestamp, countdown]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const onResendOTP = async () => {
    setIsResending(true);

    await axiosDefault
      .post("/otp", { email: email })
      .then((res) => {
        const result = res.data.result;
        const sent_ts = result.data.sent_ts;

        setOTPSentTimestamp(sent_ts);
        setIsResending(false);
        OTPData.add(email, sent_ts);

        setCountdown(calculateRemainingTime(sent_ts, initialCountdown));
        setShowResend(false);
      })
      .catch((err) => {
        const error = err.response.data;
        const message = error.result.message;
        setIsResendError(true);
        setResendError(message);
        OTPData.remove();
        if (err?.response?.status === 500) {
          setInternalError(true);
        }
      });
  };

  const onSubmit = async (values, { setSubmitting, setFieldError }) => {
    await axiosDefault
      .delete(`/delete/${values.code}`)
      .then(() => {
        setSuccess(true);
        OTPData.remove();
      })
      .catch((err) => {
        const error = err.response.data;
        const message = error.result.message;
        setFieldError("code", message);
        if (err?.response?.status === 500) {
          setInternalError(true);
        }
      });

    setSubmitting(false);
  };
  return (
    <>
      <div className="bg-holo-secondary p-5 rounded-xl mt-10">
        <h2 className="font-bold text-2xl flex my-2">
          <img src={cdnList.envelope} className="" alt="" />
          <span className="ml-2">Confirmation code sent!</span>
        </h2>
        <p>
          We have successfully sent a confirmation code to{" "}
          <span className="text-holo-primary font-bold">{email}</span>. If you
          have not received the email after a few minutes, please check your
          spam folder or refresh this page to re-enter your email address.
        </p>
      </div>
      <Formik
        initialValues={{ code: "" }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors, touched, setFieldValue }) => (
          <Form>
            <div className="w-full sm:w-1/3 mt-5">
              <FieldFormik
                label="Enter confirmation code"
                id="code"
                type="number"
                name="code"
                labelBackground="bg-white"
                error={errors.code}
                touched={touched.code}
                onChange={(e) => setFieldValue("code", e.target.value)}
              />
              {isResendError && (
                <div className="text-red-500 text-sm font-bold py-1">
                  {resendError}
                </div>
              )}
            </div>
            <button
              type="submit"
              className={`bg-holo-primary-light font-bold text-sm py-3 px-5 rounded-3xl mt-4 ${
                isSubmitting &&
                "dot-loading-animation bg-holo-secondary text-gray-700"
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Deleting" : "Delete user data"}
            </button>
            {!isResendError && (
              <>
                {isResending ? (
                  <>
                    <span className="text-gray-700 ml-5 dot-loading-animation">
                      ↻ Resending code
                    </span>
                  </>
                ) : (
                  <>
                    {showResend ? (
                      <button
                        type="button"
                        className="ml-5 text-holo-primary"
                        onClick={onResendOTP}
                      >
                        ↻ Resend Code
                      </button>
                    ) : (
                      <span className="text-holo-primary ml-5">
                        ↻ Resend code in{" "}
                        <span className="text-gray-700 font-bold">
                          {formatTime(countdown)}
                        </span>
                      </span>
                    )}
                  </>
                )}
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DeleteUserForm;
