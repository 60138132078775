import React from "react";
import { withCommaDelimiterFormat } from "../../../utils/FieldValueFormat/calculationFieldsFormat";

function TableRow({ period }) {

  return (
    <tr>
      <td className="text-center font-normal text-sm p-2">
        {period.period}
      </td>
      <td className="text-center font-normal text-sm p-2">
        {withCommaDelimiterFormat(period.monthly_payment, true)}
      </td>
      <td className="text-center font-normal text-sm p-2">
        {withCommaDelimiterFormat(period.principal, true)}
      </td>
      <td className="text-center font-normal text-sm p-2">
        {withCommaDelimiterFormat(period.interest, true)}
      </td>
      <td className="text-center font-normal text-sm p-2">
        {withCommaDelimiterFormat(period.principalBalance, true)}
      </td>
    </tr>
  );
}

export default TableRow;