import React from "react";
import { Field } from "formik";
import InputErrorMessage from "../../InputErrorMessage";

const GroupRadioFormik = ({
  name = "",
  options = [],
  error = "",
  touched = "",
  setFieldValue,
  value,
  ...props
}) => {
  return (
    <>
      <ul className="grid w-full gap-2 grid-cols-1 md:grid-cols-3">
        {options.map((item) => (
          <li key={item.no}>
            <Field
              type="radio"
              id={item.id}
              name={name}
              value={item.value}
              className="hidden peer"
              checked={value === item.value}
              onChange={(e) => {
                setFieldValue("property_type", e.target.value);
              }}
              {...props}
            />
            <label
              htmlFor={item.id}
              className={`flex flex-col items-center justify-center h-full p-5 border rounded-lg cursor-pointer ${
                value === item.value
                  ? "text-white bg-holo-primary"
                  : "text-holo-primary bg-holo-secondary"
              } border rounded-lg cursor-pointer ${
                error && touched && "border-red-500 text-red-500"
              }`}
            >
              <img
                src={
                  value === item.value ? item.icon.selected : item.icon.default
                }
                alt=""
              />
              <div className="font-semibold mt-5">{item.value}</div>
            </label>
          </li>
        ))}
      </ul>
      <InputErrorMessage error={error} touched={touched} />
    </>
  );
};

export default GroupRadioFormik;
