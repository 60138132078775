import React, { useEffect } from "react";

const PageNotFound = ({ title }) => {

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className="my-auto pt-5 mt-5">
      <div className="bg-white">
        <div className="flex justify-center">
          <div className="flex items-center h-screen-50">
            <img
              className="h-96 w-auto"
              src="https://cdn.fivenapp.com/Error-svg/PageNotFound.svg"
              alt="Page not found"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
