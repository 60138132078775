import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const MetaHeader = () => {
  const [ogUrl, setOgUrl] = useState("http://fivenapp.com");

  useEffect(() => {
    setOgUrl(window.location.href);
  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:url" content={ogUrl} />
      </Helmet>
    </>
  );
}

export default MetaHeader;
