/**
 * -----------------------------------------------------------
 * Use for form fields with string / text type
 * -----------------------------------------------------------
 */

// Format field to ###,###,###
export const withCommaDelimiterFormat = (number, isDoubled) => {
  if (number) {
    let parsedNumber = parseFloat(number.toString().replace(/,/g, ""));
    if (!isNaN(parsedNumber)) {
      if (isDoubled) {
        parsedNumber = parseFloat(parsedNumber.toFixed(2));
      }
      return parsedNumber.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
    }
  }

  return "";
};

// Accepts number with 2 floated points only
export const withTwoDecimalPlaces = (number) => {
  const pattern = /^\d+(\.\d{0,2})?$/;

  if (pattern.test(number)) {
    return number;
  }

  return "";
};
