import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FieldFormik from "../UI/Formik/FieldFormik";
import { axiosDefault, localData } from "../../../utils/Utils";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const { OTPData } = localData;

const SendOTPForm = ({ setIsValidEmail, setEmail, setOTPSentTimestamp, setInternalError }) => {
  const onSubmit = async (values, { setSubmitting, setFieldError }) => {
    await axiosDefault
      .post("/otp", values)
      .then((res) => {
        const result = res.data.result;
        const sent_ts = result.data.sent_ts;

        OTPData.add(values.email, sent_ts);

        setOTPSentTimestamp(sent_ts);
        setEmail(values.email);
        setIsValidEmail(true);
      })
      .catch((err) => {
        const error = err.response.data;
        const message = error.result.message;
        setFieldError("email", message);
        OTPData.remove();
        if (err?.response?.status === 500) {
          setInternalError(true);
        }
      });

    setSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors, touched, setFieldValue }) => (
          <Form>
            <div className="w-full sm:w-1/3">
              <FieldFormik
                label="Email"
                id="email"
                type="email"
                name="email"
                labelBackground="bg-white"
                error={errors.email}
                touched={touched.email}
                onChange={(e) => setFieldValue("email", e.target.value)}
              />
            </div>
            <button
              type="submit"
              className={`bg-holo-primary-light font-bold text-sm py-3 px-5 rounded-3xl mt-4 ${
                isSubmitting &&
                "dot-loading-animation bg-holo-secondary text-gray-700"
              }`}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending" : "Send confirmation code"}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SendOTPForm;
