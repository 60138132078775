export const ageRange = (value) => {
  if (value && parseInt(value) < 18) {
    return "Client Age cannot be less than 18";
  } else if (value && parseInt(value) > 65) {
    return "Client Age cannot be more than 65";
  }

  return undefined;
};

export const max100 = (value) => {
  if (value && parseInt(value) > 100) {
    return "Maximum value is 100";
  }

  return undefined;
};

export const loanYears = (age, loan_years) => {
  const maxYears = Math.abs(65 - age);
  if (loan_years > maxYears) {
    return "Loan Term plus Client Age cannot be more than 65";
  }

  return undefined;
};
