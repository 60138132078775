import axios from "axios";

export const computeLoanAmount = (property_amount, downpayment) => {
  return property_amount.replace(/,/g, "") - (property_amount.replace(/,/g, "") * (downpayment / 100));
};

export const computeMonthlyGrossIncome = (monthly_amortization) => {
  return Math.ceil(monthly_amortization / 0.4 / 100) * 100;
};

export const computeMonthlyAmortization = (
  interest,
  loan_years,
  loan_amount
) => {
  let factor_rate = 0;
  let monthly_amortization;

  const x = interest / 100 / 12;
  const y = loan_years * 12;
  const z = (1 + x) ** y;

  factor_rate = (z * x) / (z - 1);
  monthly_amortization = factor_rate * loan_amount;

  return monthly_amortization;
};

export const cdnList = {
  holoFooter: "https://cdn.fivenapp.com/Logo/holo-footer.svg",
  android: "https://cdn.fivenapp.com/Logo/google-play-badge.svg",
  holoLogo: "https://cdn.fivenapp.com/Logo/holo-logo.svg",
  holoText: "https://cdn.fivenapp.com/Logo/holo-text.svg",
  rafiki: "https://cdn.fivenapp.com/Logo/rafiki.svg",
  splashImage: "https://cdn.fivenapp.com/Logo/splash-image.svg",
  cardTop: "https://cdn.fivenapp.com/Logo/horizontal-card-top.svg",
  cardBottom: "https://cdn.fivenapp.com/Logo/horizontal-card-bottom.svg",
  brokerForm: "https://cdn.fivenapp.com/Logo/broker-form.svg",
  chooseRole: "https://cdn.fivenapp.com/Logo/choose-role.svg",
  home: "https://cdn.fivenapp.com/Logo/home.svg",
  selectedHome: "https://cdn.fivenapp.com/Logo/ic_home_selected.svg",
  fileText: "https://cdn.fivenapp.com/Logo/ic_file-text.svg",
  key: "https://cdn.fivenapp.com/Logo/key.svg",
  selectedKey: "https://cdn.fivenapp.com/Logo/ic_key_selected.svg",
  mapPin: "https://cdn.fivenapp.com/Logo/map-pin.svg",
  selectedPin: "https://cdn.fivenapp.com/Logo/ic_map-pin_selected.svg",
  pen: "https://cdn.fivenapp.com/Logo/pen.svg",
  users: "https://cdn.fivenapp.com/Logo/users.svg",
  maskGroup: "https://cdn.fivenapp.com/Logo/Mask%20group.svg",
  draft: "https://cdn.fivenapp.com/Logo/DRAFT%201.svg",
  envelope: "https://cdn.fivenapp.com/email-logo/enveloped.svg",
};

export const axiosDefault = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "App-Version": process.env.REACT_APP_VER,
  },
});

export const localData = {
  OTPData: {
    add: (email, timestamp) => {
      const data = { email: email, timestamp: timestamp };
      localStorage.setItem("otp_data", JSON.stringify(data));
    },
    retrieve: () => {
      const dataJSON = localStorage.getItem("otp_data");
      return JSON.parse(dataJSON);
    },
    remove: () => {
      localStorage.removeItem("otp_data");
    },
  },
};

export const calculateRemainingTime = (timestamp, initialCountdown) => {
  const sentTime = new Date(timestamp).getTime();
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - sentTime;
  const remainingTime = initialCountdown - Math.floor(timeDifference / 1000);

  return remainingTime;
};
