import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import GooglePlayImage from "../_partials/GooglePlayImage";
import { cdnList } from "../../utils/Utils";
import MetaHeader from "../helper/MetaHeader";

const Index = ({ title }) => {

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <MetaHeader />
      <div className="flex flex-col md:flex-row items-center md:justify-between mt-5 gap-10">
        <div className="order-1">
          <h1 className="text-5xl font-bold mb-5">
            Your first step to achieving <br /> your dream{" "}
            <span
              className="vector-highlighter px-10 py-4"
              style={{ marginLeft: "-25px" }}
            >
              home
            </span>
          </h1>
          <p>Get to know what you can afford.</p>
          <p className="mb-5">
            Try calculating your home loan monthly amortization.
          </p>
          <Link
            to="/calculate"
            className="bg-holo-primary-light px-5 py-3 rounded-3xl font-bold mb-5 hover:bg-holo-primary hover:text-white"
          >
            Try Ho-Lo for free
          </Link>
        </div>
        <div className="order-2">
          <div className="relative p-5">
            <img src={cdnList.splashImage} alt="Main" className=" rounded-xl" />
            <div className="absolute top-10 -right-4 md:-right-10 flex items-center justify-center">
              <img
                className="animate-float-up-down"
                src={cdnList.cardTop}
                alt="Overlay Top"
              />
            </div>
            <div className="absolute inset-x-0 -bottom-8 -left-20 md:bottom-5 md:-left-96 flex items-center justify-center">
              <img
                className="animate-float-left-right"
                src={cdnList.cardBottom}
                alt="Overlay Bottom"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-pexel p-10 relative mt-10 mb-10 sm:mt-5 rounded-xl">
        <div className="bg-pexel-gradient rounded-xl"></div>
        <div className="relative z-50">
          <h1 className="text-white text-3xl font-bold w-full text-center mb-20">
            What ho-lo can do for you
          </h1>
          <div className="flex flex-col sm:flex-row gap-20">
            <div className="flex-grow">
              <img src={cdnList.pen} alt="" />
              <h3 className="text-white text-xl mt-3 mb-3">
                Get a personalized calculation
              </h3>
              <p className="text-white">
                Get to know what you can afford. Try calculating your home loan
                monthly amortization
              </p>
            </div>
            <div className="flex-grow">
              <img src={cdnList.fileText} alt="" />
              <h3 className="text-white text-xl mt-3 mb-3">
                Calculate offline
              </h3>
              <p className="text-white">
                Get to know what you can afford. Try calculating your home loan
                monthly amortization
              </p>
            </div>
            <div className="flex-grow">
              <img src={cdnList.users} alt="" />
              <h3 className="text-white text-xl mt-3 mb-3">
                Create amortization forms for clients
              </h3>
              <p className="text-white">
                Get to know what you can afford. Try calculating your home loan
                monthly amortization
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center md:justify-between mt-5 gap-10">
        <div className="order-1">
          <h1 className="text-3xl font-bold mb-5 mt-5">
            Calculate and save<span className="text-holo-green">*</span> <br />
            your forms from anywhere
          </h1>
          <p className="mt-5 mb-5">
            Access the ho-lo app wherever you are and save your forms on your
            mobile phone
          </p>
          <p className="mb-5 text-holo-green">
            *feature only available for brokers/agents
          </p>
          <GooglePlayImage
            linkClass="hidden md:block"
            imgClass="start-self mt-3"
          />
        </div>
        <div className="order-2 flex">
          <div className="relative">
            <div className="h-56 w-full absolute bottom-0 bg-gradient-to-t from-transparent to-white"></div>
            <img className="" src={cdnList.brokerForm} alt="Broker Form" />
          </div>
          <div className="relative">
            <div className="h-60 w-full absolute bottom-0 bg-gradient-to-t from-transparent to-white"></div>
            <img className="mt-12" src={cdnList.chooseRole} alt="Choose Role" />
          </div>
        </div>
      </div>
      <GooglePlayImage
        linkClass="block md:hidden text-center"
        imgClass="mx-auto"
      />
      <h1 className="text-3xl font-bold mb-5 mt-10 text-center">
        Calculate and save<span className="text-holo-green">*</span> <br />
        your forms from anywhere
      </h1>
      <p className="mt-5 mb-5 text-center">
        Access the ho-lo app wherever you are and save your forms on your mobile
        phone
      </p>
      <img className="mx-auto" src={cdnList.draft} alt="Draft" />
    </>
  );
};

export default Index;
