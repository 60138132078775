import React, { useEffect } from "react";

const InternalError = () => {

  useEffect(() => {
    document.title = "Internal server error";
  }, []);

  return (
    <div className="my-auto pt-5 mt-5">
      <div className="bg-white">
        <div className="flex justify-center">
          <div className="flex items-center h-screen-50">
            <img
              className="h-96 w-auto"
              src="https://cdn.fivenapp.com/Error-svg/500Error.jpg"
              alt="Page not found"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalError;
