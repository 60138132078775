import React from "react";
import { Link } from "react-router-dom";
import { cdnList } from "../../utils/Utils";

const Header = () => {
  return (
    <>
      <div className="container mx-auto p-4 fixed top-0 left-0 right-0 bg-white z-50">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <img src={cdnList.holoLogo} alt="" />
              <img className="ml-3" src={cdnList.holoText} alt="" />
            </Link>
          </div>
          {/* <div className="flex items-center">
            <Link to="/" className="p-2 text-holo-primary font-bold">
              Login
            </Link>
            <Link to="/" className="p-2 text-holo-primary font-bold">
              Contact Us
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Header;
