import React, { useEffect, useState } from "react";
import { axiosDefault } from "../../utils/Utils";
import InternalError from "./InternalError";
import { useParams } from "react-router-dom";

const VerifyUser = ({ title }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [username, setUsername] = useState(null);
  const [error, setError] = useState(null);
  const [isInternalError, setInternalError] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    document.title = title;
    isConfirmed();
  }, [title]);

  const isConfirmed = async () => {
    try {
      const res = await axiosDefault.get("account/verify/" + token);
      setUsername(res?.data?.result?.data?.fname);
      setIsSuccess(true);
    } catch (err) {
      setError(err?.response?.data?.result?.message);
      if (err?.response?.status === 500) {
        setInternalError(true);
      }
    }
  };

  return (
    <div className="email-bg mt-1">
      {
        isInternalError ? <InternalError /> : (
          <>
            {!isSuccess && !error ? (
              <div className="bg-white">
                <div className="flex justify-center">
                  <div className="flex items-center justify-center h-screen-50">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-purple-500"></div>
                  </div>
                </div>
              </div>
            ) : error && !isSuccess ? (
              <div className="bg-white">
                <div className="flex justify-center">
                  <div className="flex flex-col items-center justify-center h-screen-50">
                    <img
                      className="h-40 w-auto"
                      src="https://cdn.fivenapp.com/Error-svg/broken-link-chain"
                      alt="Error"
                    />
                    <div className="mt-8">
                      <p className="text-center font-medium">{error}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              username && (
                <div className="bg-white">
                  <div className="flex justify-center">
                    <div className="flex items-center">
                      <img
                        className="h-96 w-auto"
                        src="https://cdn.fivenapp.com/email-logo/email-confirmation-logo.svg"
                        alt="Email Logo"
                      />
                    </div>
                  </div>
                  <div className="mt-8">
                    <p className="text-center welcome-msg">You made it! Welcome to Ho-Lo!</p>
                    <p className="text-center greeting-msg">
                      Hi <span className="font-medium">{username}</span>, we’re thrilled you’re here!
                    </p>
                    <p className="text-center greeting-msg mt-6">
                      Your email has been successfully verified. You may now login to your account in the
                      Ho-Lo mobile app.
                    </p>
                  </div>
                </div>
              )
            )}
          </>
        )
      }
    </div>
  );
};

export default VerifyUser;
