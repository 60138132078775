import React, { memo } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Components
import Header from "./components/_partials/Header";
import Footer from "./components/_partials/Footer";

// Pages
import Index from "./components/pages/Index";
import DeleteUser from "./components/pages/DeleteUser";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Calculate from "./components/pages/Calculate";
import PageNotFound from "./components/pages/PageNotFound";
import VerifyUser from "./components/pages/VerifyUser";

const App = () => {

  const appName = process.env.REACT_APP_NAME || "Five&App OPC"; // Assign a default value if env variable is not defined

  const MemoizedVerifyUser = memo(VerifyUser);
  
  return (
    <>
      <Router>
        <Header />
        <div className="container mx-auto p-4 pt-20 flex flex-col min-h-screen">
          <div className="flex-grow">
            <Routes>
              <Route path="/" element={<Index title={"Home | " + appName} />} />
              <Route path="/delete-user" exact={true} element={<DeleteUser title={"Delete User | " + appName} />} />
              <Route path="/privacy-policy" exact={true} element={<PrivacyPolicy title={"Privacy Policy | " + appName} />} />
              <Route path="/calculate" exact={true} element={<Calculate title={"Calculate | " + appName} />} />
              <Route path="/account/verify/:token" exact={true} element={<MemoizedVerifyUser title={"User verification"} />} />
              <Route path="*" element={<PageNotFound title={"Page not found"} />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </>
  );
};

export default App;
