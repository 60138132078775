import React, { useState, useEffect } from "react";
import ScheduleResult from "../_partials/ScheduleResult";
import CalculationForm from "../_partials/CalculationForm";
import { cdnList } from "../../utils/Utils";
import MetaHeader from "../helper/MetaHeader";

const Calculate = ({ title }) => {
  const [showResult, setShowResult] = useState(false);
  const [formData, setFormData] = useState({
    interest: "",
    loan_years: "",
    loan_amount: "",
  });

  useEffect(() => {
    document.title = title;
  }, [title]);

  const onSubmit = (values, { setSubmitting }) => {
    const { interest, loan_years, loan_amount } = values;

    setFormData({
      ...formData,
      interest: interest,
      loan_years: loan_years,
      loan_amount: loan_amount,
    });

    setShowResult(true);

    // Reset Form
    setSubmitting(false);
  };

  return (
    <>
      <MetaHeader />
      <h1 className="text-3xl font-bold py-5">
        Calculate your monthly amortization
      </h1>
      <div className="flex flex-col md:flex-row md:justify-between gap-4">
        <div className="order-1 w-full ">
          <div className="bg-holo-inverse p-5 rounded-xl w-full shadow-sm">
            <CalculationForm onSubmit={onSubmit} />
          </div>
        </div>
        <div className="order-2 w-full">
          <div className="bg-holo-inverse p-5 rounded-xl w-full shadow-sm h-full">
            {showResult ? (
              <>
                <ScheduleResult formData={formData} />
              </>
            ) : (
              <>
                <div className="flex justify-center mt-10">
                  <img
                    src={ cdnList.rafiki }
                    alt="No Data Available"
                  />
                </div>
                <div className="font-bold text-center">No data available</div>
                <div className="text-center text-gray-500">
                  Fill the form on the left and see the results here
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Calculate;
