import React, { useEffect, useState } from "react";
import SendOTPForm from "../_partials/forms/SendOTPForm";
import DeleteUserForm from "../_partials/forms/DeleteUserForm";
import { localData } from "../../utils/Utils";
import InternalError from "./InternalError";
import MetaHeader from "../helper/MetaHeader";

const { OTPData } = localData;

const DeleteUser = ({ title }) => {
  const [isSuccess, setSuccess] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [OTPSentTimestamp, setOTPSentTimestamp] = useState("");
  const [email, setEmail] = useState("");
  const [isInternalError, setInternalError] = useState(false);

  useEffect(() => {
    const otp_data = OTPData.retrieve();

    if (otp_data) {
      const { timestamp, email } = otp_data;

      setOTPSentTimestamp(timestamp);
      setEmail(email);
      setIsValidEmail(true);
    }

    document.title = title;
  }, [title]);

  return (
    <div className="my-10">
      <MetaHeader />
      {
        isInternalError ? <InternalError /> : (
          <>
            {isSuccess ? (
              <>
                <h1 className="font-bold text-4xl py-5">
                  You have successfully deleted your user data
                </h1>
                <p className="my-5">
                  Thank you for your continued trust and support. We value your
                  privacy and remain committed to providing you with the best possible
                  user experience while respecting your data preferences.
                </p>
              </>
            ) : (
              <>
                <h1 className="font-bold text-4xl py-5">Delete User Data</h1>
                <p className="my-5">
                  Your privacy is our top priority, and we want to ensure that you
                  have full control over how your data is handled. By opting out, you
                  can choose to restrict the collection and usage of your app user
                  data, thereby maintaining your privacy and ensuring that your
                  information is not utilized for any purposes without your explicit
                  consent.
                </p>
                <p className="my-5">
                  We want to assure you that this decision will not affect your
                  ability to continue using our app or accessing its features.
                  However, it may result in a less personalized experience, as certain
                  functionalities rely on app user data for optimization and
                  customization.
                </p>
                <p className="my-5">
                  Please provide your email address below if you wish to proceed, a
                  confirmation code and further instructions will be sent to your
                  email.
                </p>

                {isValidEmail ? (
                  <DeleteUserForm
                    setSuccess={setSuccess}
                    email={email}
                    OTPSentTimestamp={OTPSentTimestamp}
                    setOTPSentTimestamp={setOTPSentTimestamp}
                    setInternalError={setInternalError}
                  />
                ) : (
                  <SendOTPForm
                    setIsValidEmail={setIsValidEmail}
                    setEmail={setEmail}
                    setOTPSentTimestamp={setOTPSentTimestamp}
                    setInternalError={setInternalError}
                  />
                )}
              </>
            )}
          </>
        )
      }
    </div>
  );
};

export default DeleteUser;
