import React from "react";

const InputErrorMessage = ({ error, touched }) => {
  return (
    error &&
    touched && (
      <div className="text-red-500 text-sm font-bold py-1">{error}</div>
    )
  );
};

export default InputErrorMessage;
