import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Custom Field Value Format
import {
  withCommaDelimiterFormat,
  withTwoDecimalPlaces,
} from "../../utils/FieldValueFormat/calculationFieldsFormat";

// Custom Validation
import {
  ageRange,
  loanYears,
  max100,
} from "../../utils/CustomValidation/calculationFieldsValidation";
import FieldFormik from "./UI/Formik/FieldFormik";
import GroupRadioFormik from "./UI/Formik/GroupRadioFormik";
import { cdnList, computeLoanAmount } from "../../utils/Utils";

// Default form validation
const formValidationSchema = Yup.object().shape({
  age: Yup.number().required("Age is required"),
  property_type: Yup.string().required("Property Type is required"),
  property_amount: Yup.string().required("Property Amount is required"),
  downpayment: Yup.string().required("Downpayment % is required"),
  loan_years: Yup.string().required("Loan Term in Years is required"),
  interest: Yup.string().required("Interest % is required"),
});

const CalculationForm = ({ onSubmit }) => {
  const formInitialValue = {
    age: "",
    property_type: "",
    property_amount: "",
    downpayment: "",
    loan_amount: "",
    loan_years: "",
    interest: "",
  };
  return (
    <>
      <Formik
        initialValues={formInitialValue}
        validationSchema={formValidationSchema}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          setFieldValue,
          errors,
          touched,
          setFieldTouched,
          values,
          resetForm,
        }) => (
          <Form>
            <h2 className="text-lg font-bold mb-5">
              Provide the following details
            </h2>

            <FieldFormik
              label="Age"
              id="age"
              type="number"
              name="age"
              error={errors.age}
              touched={touched.age}
              value={values.age}
              onChange={(e) => {
                setFieldValue("age", e.target.value);
                setFieldTouched("age", true, false);
              }}
              validate={ageRange}
            />

            <h3 className="font-bold text-md mt-5 mb-3">
              Select Property Type
            </h3>

            <div className="mb-10">
              <GroupRadioFormik
                name="property_type"
                error={errors.property_type}
                touched={touched.property_type}
                setFieldValue={setFieldValue}
                value={values.property_type}
                options={[
                  {
                    no: 1,
                    id: "house-and-lot",
                    value: "House & Lot",
                    icon: {
                      default: cdnList.home,
                      selected: cdnList.selectedHome,
                    },
                  },
                  {
                    no: 2,
                    id: "lot-only",
                    value: "Lot Only",
                    icon: {
                      default: cdnList.mapPin,
                      selected: cdnList.selectedPin,
                    },
                  },
                  {
                    no: 3,
                    id: "condominium",
                    value: "Condominium",
                    icon: {
                      default: cdnList.key,
                      selected: cdnList.selectedKey,
                    },
                  },
                ]}
              />
            </div>

            <div className="flex flex-col md:flex-row md:justify-between gap-4">
              <div className="order-1 w-full">
                <FieldFormik
                  label="Property Amount"
                  id="property_amount"
                  type="text"
                  name="property_amount"
                  error={errors.property_amount}
                  touched={touched.property_amount}
                  onChange={(e) => {
                    setFieldValue(
                      "property_amount",
                      withCommaDelimiterFormat(e.target.value, false)
                    );
                    setFieldValue(
                      "loan_amount",
                      computeLoanAmount(e.target.value, values.downpayment)
                    );
                    setFieldTouched("property_amount", true, false);
                  }}
                />
              </div>
              <div className="order-2 w-full">
                <FieldFormik
                  label="Downpayment %"
                  id="downpayment"
                  name="downpayment"
                  error={errors.downpayment}
                  touched={touched.downpayment}
                  onChange={(e) => {
                    setFieldValue(
                      "downpayment",
                      withTwoDecimalPlaces(e.target.value)
                    );
                    setFieldValue(
                      "loan_amount",
                      computeLoanAmount(values.property_amount, e.target.value)
                    );
                    setFieldTouched("downpayment", true, false);
                  }}
                  validate={max100}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between gap-4 mt-4">
              <div className="order-1 w-full">
                <FieldFormik
                  label="Loan Amount"
                  id="loan_amount"
                  type="text"
                  name="loan_amount"
                  value={withCommaDelimiterFormat(
                    values.loan_amount.toLocaleString(),
                    true
                  )}
                  disabled
                />
              </div>
              <div className="order-2 w-full">
                <FieldFormik
                  label="Loan Terms in Years"
                  id="loan_years"
                  type="number"
                  name="loan_years"
                  touched={touched.loan_years}
                  error={errors.loan_years}
                  validate={(value) => loanYears(values.age, value)}
                  onChange={(e) => {
                    setFieldValue("loan_years", e.target.value);
                    setFieldTouched("loan_years", true, false);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between gap-4 mt-4">
              <div className="order-1 w-full">
                <FieldFormik
                  label="Interest %"
                  id="interest"
                  type="text"
                  name="interest"
                  error={errors.interest}
                  touched={touched.interest}
                  onChange={(e) => {
                    setFieldValue(
                      "interest",
                      withTwoDecimalPlaces(e.target.value)
                    );
                    setFieldTouched("interest", true, false);
                  }}
                  validate={max100}
                />
              </div>
              <div className="order-2 w-full"></div>
            </div>
            <div className="flex justify-end mt-10">
              <button
                type="button"
                className="bg-holo-secondary text-holo-primary py-3 px-6 rounded-3xl font-bold mr-5"
                onClick={resetForm}
              >
                Clear
              </button>
              <button
                type="submit"
                className="bg-holo-primary text-white py-3 px-6 rounded-3xl font-bold"
                disabled={isSubmitting}
              >
                Calculate
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CalculationForm;
