import React from "react";
import { withCommaDelimiterFormat } from "../../utils/FieldValueFormat/calculationFieldsFormat";
import TableRow from "./tbody/Schedule";
import {
  computeMonthlyAmortization,
  computeMonthlyGrossIncome,
} from "../../utils/Utils";

const ScheduleResult = ({
  formData: { loan_amount, loan_years, interest },
}) => {
  const monthly_payment = computeMonthlyAmortization(
    parseFloat(interest),
    parseInt(loan_years),
    loan_amount
  );

  const requiredGrossIncome = computeMonthlyGrossIncome(monthly_payment);

  const schedules = [];

  // P1
  const interestP1 = (loan_amount * (parseFloat(interest) * 0.01)) / 12;
  const principalP1 = monthly_payment - interestP1;
  const principalBalanceP1 = loan_amount - principalP1;

  schedules.push({
    period: 1,
    monthly_payment: monthly_payment,
    interest: interestP1,
    principal: principalP1,
    principalBalance: principalBalanceP1,
  });

  // P2-P12
  let previousPrincipalBalance = principalBalanceP1;

  for (let period = 2; period <= 12; period++) {
    const interestPeriod = (previousPrincipalBalance * (interest * 0.01)) / 12;
    const principal = monthly_payment - interestPeriod;
    const principalBalance = previousPrincipalBalance - principal;

    schedules.push({
      period: period,
      monthly_payment: monthly_payment,
      interest: interestPeriod,
      principal: principal,
      principalBalance: principalBalance,
    });

    previousPrincipalBalance = principalBalance;
  }

  return (
    <>
      <h1 className="font-bold text-lg py-5">
        Here{"'"}s your monthly amortization
      </h1>
      <div className="bg-holo-secondary p-5 rounded-2xl">
        <p className="text-sm">Required gross monthly income</p>
        <p className="font-bold text-3xl">
          Php {withCommaDelimiterFormat(requiredGrossIncome, true)}
        </p>
      </div>
      <table className="w-full my-5 overflow-scroll">
        <thead>
          <tr>
            <th className="text-center font-bold text-sm p-2">Period</th>
            <th className="text-center font-bold text-sm p-2">
              Monthly Payment
            </th>
            <th className="text-center font-bold text-sm p-2">Principal</th>
            <th className="text-center font-bold text-sm p-2">Interest</th>
            <th className="text-center font-bold text-sm p-2">
              Principal Balance
            </th>
          </tr>
        </thead>
        <tbody>
          {schedules.map((period) => (
            <TableRow 
              key={period.period}
              period={period}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ScheduleResult;
